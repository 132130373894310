import {
  CoffeeOutlined,
  DesktopOutlined,
  DollarOutlined,
  FundOutlined,
  // NotificationOutlined,
  RocketOutlined,
  TeamOutlined,
  TrophyOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import Index from 'pages/Dashboard/Index';
import { Tournaments } from 'pages/Dashboard/Tournaments';
import PersonalTransactions from 'pages/Dashboard/PersonalTransactions';
import { InternalTransactions } from 'pages/Dashboard/InternalTransactions';
import { PlayingMyself } from 'pages/Dashboard/PlayingMyself';
import { Totals } from 'pages/Dashboard/Totals';
import { Craft } from 'pages/Dashboard/Craft';
// import Notifications from 'pages/Dashboard/Notifications';
import { Tools } from 'pages/Dashboard/Tools';

const MENU_GROUPS = {
  transactions: {
    key: 'transactions',
    label: 'Transactions',
    icon: <DollarOutlined />,
  },
};

export const ROUTES = [
  {
    path: '/',
    Component: Index,
    menu: {
      label: 'Dashboard',
      group: null,
      icon: <DesktopOutlined />,
    },
  },
  {
    path: '/personal-transactions',
    Component: PersonalTransactions,
    menu: {
      label: 'Personal',
      group: MENU_GROUPS.transactions,
      icon: <UserSwitchOutlined />,
    },
  },
  {
    path: '/internal-transactions',
    Component: InternalTransactions,
    menu: {
      label: 'Internal',
      group: MENU_GROUPS.transactions,
      icon: <TeamOutlined />,
    },
  },
  {
    path: '/tournaments',
    Component: Tournaments,
    menu: {
      label: 'Tournaments',
      group: null,
      icon: <TrophyOutlined />,
    },
  },
  {
    path: '/playing-myself',
    Component: PlayingMyself,
    menu: {
      label: 'Playing myself',
      group: null,
      icon: <CoffeeOutlined />,
    },
  },
  {
    path: '/totals',
    Component: Totals,
    menu: {
      label: 'Totals',
      group: null,
      icon: <FundOutlined />,
    },
  },
  {
    path: '/craft',
    Component: Craft,
    menu: {
      label: 'Craft',
      group: null,
      icon: <RocketOutlined />,
    },
  },
  {
    path: '/tools',
    Component: Tools,
  },
  // {
  //   path: '/notifications',
  //   Component: Notifications,
  //   menu: {
  //     label: 'Notifications',
  //     group: null,
  //     icon: <NotificationOutlined />,
  //   },
  // },
];
